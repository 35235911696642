import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export default function CompanyPage() {
  const [companies, setCompanies] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        let url;

        if (process.env.NODE_ENV === "production") {
          // Production environment
          url = "https://api.pipeline.careers/company"; // Replace with your production API endpoint
        } else {
          // Development (local) environment
          url = "http://127.0.0.1:5000/company"; // Replace with your local API endpoint
        }

        const response = await fetch(url);
        const data = await response.json();
        console.log(data);
        setCompanies(data);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };

    fetchCompanies();
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSortChange = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const filteredCompanies = companies.filter((company) =>
    company.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedCompanies = filteredCompanies.sort((a, b) => {
    if (sortOrder === "asc") {
      return a.name.localeCompare(b.name);
    } else {
      return b.name.localeCompare(a.name);
    }
  });

  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 mb-8">
          Companies
        </h2>
        <div className="flex justify-between items-center mb-8">
          <input
            type="text"
            placeholder="Search companies..."
            value={searchTerm}
            onChange={handleSearch}
            className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button
            onClick={handleSortChange}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Sort {sortOrder === "asc" ? "Descending" : "Ascending"}
          </button>
        </div>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {sortedCompanies.map((company) => (
            <div
              key={company.id}
              className="bg-white overflow-hidden shadow rounded-lg"
            >
              <div className="px-4 py-5 sm:p-6">
                <img
                  src={company.logo_url}
                  alt={company.name}
                  className="h-12 w-auto mb-4"
                />
                <h3 className="text-lg font-medium text-gray-900">
                  {company.name}
                </h3>
                <Link
                  to={company.board_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  View Job Board
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";

import LandingPage from "./landing/LandingPage";
import CompanyPage from "./company/CompanyPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/company" element={<CompanyPage />} />∏
        <Route path="*" element={<LandingPage />} />
      </Routes>
    </Router>
  );
}

export default App;

const exampleNewsletter = `
<!DOCTYPE html>
<html lang="en">

<head>
  <title>Your Dream Job Awaits</title>
  <meta charset="UTF-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <meta name="description" content="Find your dream job with our curated list of opportunities in the tech industry." />
  <style>
    /* Add responsive CSS classes */
    @media (max-width: 640px) {
      .max-w-5xl {
        max-width: 100%;
      }

      .text-xl {
        font-size: 1.5rem;
      }

      .w-12,
      .h-12 {
        width: 2rem;
        height: 2rem;
      }

      .text-base {
        font-size: 1rem;
      }

      .text-sm {
        font-size: 0.875rem;
      }
    }
  </style>
  <script src="https://cdn.tailwindcss.com"></script>
</head>

<body class="bg-gray-100 font-sans">
  <main class="max-w-5xl mx-auto">
    <div class="bg-white rounded shadow-md p-5 border border-gray-300">
      <header class="border-b border-gray-300 pb-2 mb-5">
        <div class="flex justify-between items-center">
          <div class="flex items-center">
            <div class="text-yellow-400 mr-2">&#9733;</div>
            <div class="text-gray-500 text-xs">
              From: alerts@createjobalert.com
            </div>
          </div>
        </div>
      </header>
      <section>
        <p class="text-gray-800 text-base leading-relaxed">
          Example job alert. Powered by createjobalert.com.
        </p>
        <div class="border-t border-gray-300 my-5"></div>
        <h2 class="text-xl mb-4">Job Alerts</h2>
        <ul class="list-none">
          <!-- Google Job Listing -->
          <li class="flex items-center mb-5">
            <img class="w-12 h-12 mr-2" src="https://logo.clearbit.com/google.com" alt="Google Logo" />
            <div class="flex-1">
              <div class="flex justify-between">
                <strong class="text-base">Google</strong>
                <div>
                  <span class="text-gray-500 text-sm">Sunnyvale, CA</span>
                </div>
              </div>
              <div class="flex-1">
                <div class="flex justify-between">
                  <p class="text-blue-500">Software Developer III</strong>
                  <div>
                    <span class="text-gray-500 text-sm">1 hour ago</span>
                  </div>
                </div>
                <p class="text-gray-600 text-sm">
                  Google seeks software developers to advance technologies
                  impacting billions. Work involves large-scale information
                  handling and a range of areas like AI, NLP, and UI design.
                </p>
              </div>
          </li>
          <!-- Meta Job Listing -->
          <li class="flex items-center mb-5">
            <img class="w-12 h-12 mr-2" src="https://logo.clearbit.com/metacareers.com" alt="Meta Logo" />
            <div class="flex-1">
              <div class="flex justify-between">
                <strong class="text-base">Meta</strong>
                <div>
                  <span class="text-gray-500 text-sm">Menlo Park, CA</span>
                </div>
              </div>
              <div class="flex-1">
                <div class="flex justify-between">
                  <p class="text-blue-500">Software Engineer, Product</strong>
                  <div>
                    <span class="text-gray-500 text-sm">1 hour ago</span>
                  </div>
                </div>
                <p class="text-gray-600 text-sm">
                  Meta is seeking a Software Engineer to develop and test
                  software applications. The role includes diverse product area
                  work and problem-solving.
                </p>
              </div>
          </li>
          <!-- OpenAI Job Listing -->
          <li class="flex items-center mb-5">
            <img class="w-12 h-12 mr-2" src="https://logo.clearbit.com/openai.com" alt="OpenAI Logo" />
            <div class="flex-1">
              <div class="flex justify-between">
                <strong class="text-base">OpenAI</strong>
                <div>
                  <span class="text-gray-500 text-sm">San Francisco, CA</span>
                </div>
              </div>
              <div class="flex-1">
                <div class="flex justify-between">
                  <p class="text-blue-500">Software Engineer, Fullstack</strong>
                  <div>
                    <span class="text-gray-500 text-sm">2 hours ago</span>
                  </div>
                </div>
                <p class="text-gray-600 text-sm">
                  OpenAI offers an opportunity for a Fullstack Software Engineer
                  to develop features for ChatGPT and the OpenAI API. The role
                  focuses on cutting-edge AI technology.
                </p>
              </div>
          </li>
          <!-- Figma Job Listing -->
          <li class="flex items-center mb-5">
            <img class="w-12 h-12 mr-2" src="https://logo.clearbit.com/figmaelements.com" alt="Figma Logo" />
            <div class="flex-1">
              <div class="flex justify-between">
                <strong class="text-base">Figma</strong>
                <div>
                  <span class="text-gray-500 text-sm">San Francisco, CA</span>
                </div>
              </div>
              <div class="flex-1">
                <div class="flex justify-between">
                  <p class="text-blue-500">Software Engineer - Growth</strong>
                  <div>
                    <span class="text-gray-500 text-sm">3 hours ago</span>
                  </div>
                </div>
                <p class="text-gray-600 text-sm">
                  Figma seeks a Software Engineer - Growth to focus on product
                  development and UI building. The role involves improving
                  growth infrastructure and user experience.
                </p>
              </div>
          </li>
          <!-- Notion Job Listing -->
          <li class="flex items-center">
            <img class="w-12 h-12 mr-2" src="https://logo.clearbit.com/notion.so" alt="Notion Logo" />
            <div class="flex-1">
              <div class="flex justify-between">
                <strong class="text-base">Notion</strong>
                <div>
                  <span class="text-gray-500 text-sm">San Francisco, CA</span>
                </div>
              </div>
              <div class="flex-1">
                <div class="flex justify-between">
                  <p class="text-blue-500">Software Engineer, Fullstack</strong>
                  <div>
                    <span class="text-gray-500 text-sm">1 hour ago</span>
                  </div>
                </div>
                <p class="text-gray-600 text-sm">
                  Notion is looking for a Fullstack Software Engineer with a
                  focus on web development. The role includes collaborative,
                  in-person work on innovative software solutions.
                </p>
              </div>
          </li>
        </ul>
      </section>
      <div class="border-t border-gray-300 my-5"></div>
      <footer class="text-center mt-5">
        <span class="text-blue-500 cursor-pointer">Update alerts</span>
        |
        <span class="text-blue-500 cursor-pointer">Unsubscribe</span>
      </footer>
    </div>
  </main>
</body>

</html>
`;

export default exampleNewsletter;

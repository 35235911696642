import React, { useState, useEffect } from "react";
import exampleNewsletter from "./ExampleNewsletter.js";
import { PopupButton } from "@typeform/embed-react";
import { Disclosure } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const faqs = [
  {
    question: "How is this different from LinkedIn Job Alerts?",
    answer:
      "This supports getting job alerts directly from the career pages.",
  },
  {
    question: "How do you plan on making money?",
    answer:
      "It's a free service for candidates. Later on I plan on charging sponsored job postings.",
  },
  {
    question: "Is my data private?",
    answer:
      "Yes.",
  },
];

const departments = [
  {
    name: "Tech",
    roles: [
      "Software Engineer",
      "Product Manager",
      "Data Scientist",
      "Product Designer",
      "IT & Network Administration",
      "Program Manager",
      "Sales Engineer",
      "Technical Writer",
      "Cybersecurity Analyst",
      "DevOps Engineer",
    ],
  },
  {
    name: "Tech Management",
    roles: [
      "Software Engineering Manager",
      "Data Science Manager",
      "Product Design Manager",
    ],
  },
  {
    name: "Business",
    roles: [
      "Management Consultant",
      "Investment Banker",
      "Business Development",
      "Financial Analyst",
      "Venture Capitalist",
    ],
  },
  {
    name: "Engineering",
    roles: [
      "Biomedical Engineer",
      "Civil Engineer",
      "Hardware Engineer",
      "Mechanical Engineer",
      "Geological Engineer",
      "Electrical Engineer",
      "Aerospace Engineer",
    ],
  },
  {
    name: "Operations",
    roles: [
      "Accountant",
      "Marketing",
      "Human Resources",
      "Recruiter",
      "Sales",
      "Administrative Assistant",
      "Chief of Staff",
      "Legal",
      "Copywriter",
      "Customer Service",
      "Supply Chain",
    ],
  },
  {
    name: "Health",
    roles: ["Clinical Research Coordinator"],
  },
  {
    name: "Other",
    roles: ["Fashion Designer", "Sustainability"],
  },
];

export default function LandingPage() {
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        let apiUrl;
        if (window.location.hostname === "localhost") {
          apiUrl = "http://127.0.0.1:5000/landing_page";
        } else {
          apiUrl = "https://api.createjobalert.com/landing_page";
        }
        const response = await fetch(apiUrl);
        const data = await response.json();
        setCompanies(data);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };

    fetchCompanies();
  }, []);

  return (
    <div className="bg-white">
      <main>
        {/* Navbar section */}
        <header className="absolute top-0 left-0 right-0 z-10">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <nav
              className="flex items-center justify-between py-6"
              aria-label="Global"
            >
              <a href="/" className="flex items-center">
                <span role="img" aria-label="Megaphone" className="text-2xl">
                  📣
                </span>
                <span
                  className="text-2xl font-semibold text-white ml-2"
                  style={{ fontFamily: "Lora" }}
                >
                  Job Alert
                </span>
              </a>

              <div className="flex items-center gap-x-4 lg:gap-x-12">
                <PopupButton
                  id="qDZQDnkj"
                  className="px-4 py-2 text-lg font-medium text-white bg-white rounded-md hover:bg-gray-300"
                >
                  <span className="text-black">Get Alerted</span>

                </PopupButton>
              </div>
            </nav>
          </div>
        </header>

        {/* Hero section */}
        <section className="relative bg-black pt-24 pb-12 sm:pt-24 sm:pb-16 lg:pt-32 lg:pb-24 overflow-hidden">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative z-10">
            <div className="text-center">
              <div>
                <h1
                  className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl mb-4"
                  style={{ fontFamily: "Lora" }}
                >
                  Never miss your <span className="text-white font-bold underline">dream job</span>,
                </h1>
                <h1
                  className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl"
                  style={{ fontFamily: "Lora" }}
                >
                  be the <span className="text-white font-bold italic">first to apply</span>
                </h1>
              </div>
              <p className="mt-4 max-w-xl mx-auto text-2xl text-gray-300">
                Get a job alert as soon as a matching job opens at
                your target company.
              </p>
              <div className="mt-8 flex flex-col items-center sm:flex-row justify-center gap-4">
                <PopupButton
                  id="qDZQDnkj"
                  className="inline-flex items-center justify-center px-8 py-4 border border-transparent text-lg font-bold rounded-full text-white bg-gradient-to-r from-teal-400 to-blue-500 hover:from-teal-500 hover:to-blue-600 md:text-xl md:px-10 h-14 shadow-lg transform transition duration-500 hover:scale-105"
                >
                  Get Alerted
                </PopupButton>
              </div>
              <p className="mt-4 text-sm text-gray-400">
                Completely free. No spam, ever.
              </p>
            </div>
          </div>
          <div className="mt-8 relative" dangerouslySetInnerHTML={{ __html: exampleNewsletter }} />
        </section>

        {/* How It Works Section */}
        <section className="bg-gray-100 py-16">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                How It Works
              </h2>
            </div>
            <div className="mt-12">
              <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                {/* Step 1 */}
                <div className="pt-6">
                  <div className="flow-root bg-white rounded-lg px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center p-3 bg-blue-500 rounded-md shadow-lg">
                          {/* Search Icon */}
                          <svg
                            className="h-6 w-6 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                            />
                          </svg>
                        </span>
                      </div>
                      <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                        Create Your Profile
                      </h3>
                      <p className="mt-5 text-base text-gray-500">
                        Sign up and tailor your profile with your skills and experience.
                      </p>
                    </div>
                  </div>
                </div>

                {/* Step 2 */}
                <div className="pt-6">
                  <div className="flow-root bg-white rounded-lg px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center p-3 bg-blue-500 rounded-md shadow-lg">
                          {/* Notification Icon */}
                          <svg
                            className="h-6 w-6 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                            />
                          </svg>
                        </span>
                      </div>
                      <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                        Set Your Preferences
                      </h3>
                      <p className="mt-5 text-base text-gray-500">
                        Choose your preferred job roles and desired companies.
                      </p>
                    </div>
                  </div>
                </div>

                {/* Step 3 */}
                <div className="pt-6">
                  <div className="flow-root bg-white rounded-lg px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center p-3 bg-blue-500 rounded-md shadow-lg">
                          {/* Mail Icon */}
                          <svg
                            className="h-6 w-6 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                            />
                          </svg>
                        </span>
                      </div>
                      <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                        Receive Job Alerts
                      </h3>
                      <p className="mt-5 text-base text-gray-500">
                        Get instant notifications for jobs that match your criteria.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Company section */}
        <section className="bg-gray-50 py-20 sm:py-28">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <h2 className="text-4xl font-extrabold text-gray-900 sm:text-5xl">
                Featured Companies
              </h2>
            </div>
            <div className="mt-12 grid grid-cols-2 gap-8 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:gap-12">
              {companies.slice(0, 20).map((company) => (
                <div
                  key={company.name}
                  className="group relative flex flex-col items-center justify-center bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out"
                  onClick={() => window.open('https://5vem9f3r40j.typeform.com/to/qDZQDnkj', '_blank')}
                >
                  <img
                    src={company.logo_url}
                    alt={company.name}
                    className="h-16 w-auto object-contain transition-transform duration-300 ease-in-out group-hover:scale-110"
                  />
                  <div className="mt-4 text-center">
                    <p className="text-lg font-semibold text-gray-900">
                      {company.name}
                    </p>
                  </div>
                  <div className="absolute inset-0 flex items-center justify-center opacity-0 bg-gray-900 bg-opacity-75 text-white transition-opacity duration-300 ease-in-out group-hover:opacity-100 cursor-pointer">
                    Create Job Alert
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Department section */}
        {/* <section className="bg-white dark:bg-gray-800 py-20 sm:py-28">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <h2 className="text-5xl font-extrabold text-gray-900 dark:text-white mb-16 text-center">
              Select Job Alerts by Department
            </h2>
            <div className="grid grid-cols-1 gap-16 sm:grid-cols-2 lg:grid-cols-3">
              {departments.map((department, index) => (
                <div
                  key={department.name}
                  className="transform transition duration-500 hover:scale-105 bg-gray-50 dark:bg-gray-700 rounded-xl shadow-xl p-8 hover:shadow-2xl"
                  style={{ transitionDelay: `${index * 100}ms` }}
                >
                  <h3 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">
                    {department.name}
                  </h3>
                  <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                    {department.roles.map((role, roleIndex) => (
                      <div
                        key={role}
                        className="group relative flex items-center justify-center bg-white dark:bg-gray-600 rounded-xl shadow-md p-6 transition-all duration-300 ease-in-out hover:shadow-lg"
                        style={{ transitionDelay: `${roleIndex * 50}ms` }}
                      >
                        <span className="text-xl text-gray-800 dark:text-gray-200 font-semibold">
                          {role}
                        </span>
                        <div className="absolute inset-0 flex items-center justify-center opacity-0 transition-opacity duration-300 ease-in-out group-hover:opacity-100">
                          <a
                            href={`/create-job-alert/${department.name}/${role}`}
                            className="inline-flex items-center justify-center py-3 px-6 font-semibold rounded-md text-white bg-gradient-to-r from-teal-500 to-blue-600 hover:bg-gradient-to-br focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 transition duration-300 ease-in-out"
                          >
                            Create Job Alert
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section> */}



        {/* FAQ Section*/}
        <div className="bg-gray-900">
          <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
            <div className="mx-auto max-w-4xl divide-y divide-gray-500">
              <h2 className="text-3xl font-extrabold leading-10 tracking-tight text-white sm:text-4xl">
                Frequently asked questions
              </h2>
              <dl className="mt-12 space-y-8 divide-y divide-gray-500">
                {faqs.map((faq) => (
                  <Disclosure as="div" key={faq.question} className="pt-6">
                    {({ open }) => (
                      <>
                        <dt>
                          <Disclosure.Button className="flex w-full items-start justify-between text-left text-lg text-white hover:text-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-teal-500 focus-visible:ring-opacity-75">
                            <span className="font-medium leading-6">
                              {faq.question}
                            </span>
                            <span className="ml-6 flex h-7 items-center">
                              {open ? (
                                <MinusSmallIcon
                                  className="h-6 w-6 text-teal-400 transition-transform duration-200"
                                  aria-hidden="true"
                                />
                              ) : (
                                <PlusSmallIcon
                                  className="h-6 w-6 text-teal-400 transition-transform duration-200"
                                  aria-hidden="true"
                                />
                              )}
                            </span>
                          </Disclosure.Button>
                        </dt>
                        <Disclosure.Panel as="dd" className="mt-4 pr-12">
                          <p className="text-lg leading-8 text-gray-300">
                            {faq.answer}
                          </p>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
              </dl>
            </div>

          </div>
        </div>

        {/* Footer Section */}
        <footer className="bg-black">
          {/* Job alerts section */}
          <div className="pt-6">
            <div className="bg-white rounded-xl shadow-xl py-8 px-6 text-center">
              <h3 className="text-xl font-bold text-gray-900 md:text-2xl">
                Ready to set up job alerts?
              </h3>
              <PopupButton
                id="qDZQDnkj"
                className="mt-4 inline-flex items-center justify-center px-10 py-4 border border-transparent text-lg font-bold rounded-full text-gray-900 bg-gradient-to-r from-teal-500 to-blue-600 hover:from-teal-600 hover:to-blue-700 md:text-xl shadow-lg transform transition duration-500 hover:scale-105"
              >
                Get Alerted
              </PopupButton>
            </div>
          </div>
          <div
            className="mx-auto max-w-7xl p-6 lg:px-8 text-center"
          >
            <p className="text-sm font-medium text-white mb-2">
              Made with <span className="text-red-600">❤️</span>
            </p>
            <a
              href="/"
              className="block text-sm font-medium text-white hover:text-gray-600"
            >
              createjobalert.com
            </a>
          </div>
        </footer>
      </main>
    </div>
  );
}
